import * as React from "react";

export const MapIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2703_3385)">
      <path
        d="M8.83325 13V26.3334L14.6666 23L21.3333 26.3334L27.1666 23V9.66669L21.3333 13L14.6666 9.66669L8.83325 13Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 9.66669V23"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 13V26.3333"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2703_3385">
        <rect width="20" height="20" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export const TaskIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15C8.71667 15 8.47933 14.904 8.288 14.712C8.096 14.5207 8 14.2833 8 14C8 13.7167 8.096 13.479 8.288 13.287C8.47933 13.0957 8.71667 13 9 13H16C16.2833 13 16.521 13.0957 16.713 13.287C16.9043 13.479 17 13.7167 17 14C17 14.2833 16.9043 14.5207 16.713 14.712C16.521 14.904 16.2833 15 16 15H9ZM9 23C8.71667 23 8.47933 22.904 8.288 22.712C8.096 22.5207 8 22.2833 8 22C8 21.7167 8.096 21.479 8.288 21.287C8.47933 21.0957 8.71667 21 9 21H16C16.2833 21 16.521 21.0957 16.713 21.287C16.9043 21.479 17 21.7167 17 22C17 22.2833 16.9043 22.5207 16.713 22.712C16.521 22.904 16.2833 23 16 23H9ZM21.675 16.3L19.525 14.15C19.3417 13.9667 19.25 13.7333 19.25 13.45C19.25 13.1667 19.3417 12.9333 19.525 12.75C19.7083 12.5667 19.9417 12.475 20.225 12.475C20.5083 12.475 20.7417 12.5667 20.925 12.75L22.35 14.175L25.9 10.625C26.1 10.425 26.3333 10.329 26.6 10.337C26.8667 10.3457 27.1 10.45 27.3 10.65C27.4833 10.85 27.575 11.0833 27.575 11.35C27.575 11.6167 27.4833 11.85 27.3 12.05L23.075 16.3C22.875 16.5 22.6417 16.6 22.375 16.6C22.1083 16.6 21.875 16.5 21.675 16.3ZM21.675 24.3L19.525 22.15C19.3417 21.9667 19.25 21.7333 19.25 21.45C19.25 21.1667 19.3417 20.9333 19.525 20.75C19.7083 20.5667 19.9417 20.475 20.225 20.475C20.5083 20.475 20.7417 20.5667 20.925 20.75L22.35 22.175L25.9 18.625C26.1 18.425 26.3333 18.329 26.6 18.337C26.8667 18.3457 27.1 18.45 27.3 18.65C27.4833 18.85 27.575 19.0833 27.575 19.35C27.575 19.6167 27.4833 19.85 27.3 20.05L23.075 24.3C22.875 24.5 22.6417 24.6 22.375 24.6C22.1083 24.6 21.875 24.5 21.675 24.3Z"
      fill="currentColor"
    />
  </svg>
);

export const ExploreIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 8.83331L9.75 12.5V25.3333C9.75 25.8195 9.94315 26.2859 10.287 26.6297C10.6308 26.9735 11.0971 27.1666 11.5833 27.1666H24.4167C24.9029 27.1666 25.3692 26.9735 25.713 26.6297C26.0568 26.2859 26.25 25.8195 26.25 25.3333V12.5L23.5 8.83331H12.5Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 12.5H26.25"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6666 16.1667C21.6666 17.1391 21.2803 18.0718 20.5926 18.7594C19.905 19.447 18.9724 19.8334 17.9999 19.8334C17.0275 19.8334 16.0948 19.447 15.4072 18.7594C14.7196 18.0718 14.3333 17.1391 14.3333 16.1667"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MoreIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1667 9.75H9.75V16.1667H16.1667V9.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.25 9.75H19.8334V16.1667H26.25V9.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.25 19.8333H19.8334V26.25H26.25V19.8333Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1667 19.8333H9.75V26.25H16.1667V19.8333Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Notif: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2703_3923)">
      <path
        d="M20.1429 18.6429H19.5V9.53571C19.5 5.75625 16.7063 2.63304 13.0714 2.11339V1.07143C13.0714 0.479464 12.592 0 12 0C11.408 0 10.9286 0.479464 10.9286 1.07143V2.11339C7.29375 2.63304 4.5 5.75625 4.5 9.53571V18.6429H3.85714C3.38304 18.6429 3 19.0259 3 19.5V20.3571C3 20.475 3.09643 20.5714 3.21429 20.5714H9C9 22.2268 10.3446 23.5714 12 23.5714C13.6554 23.5714 15 22.2268 15 20.5714H20.7857C20.9036 20.5714 21 20.475 21 20.3571V19.5C21 19.0259 20.617 18.6429 20.1429 18.6429ZM12 21.8571C11.2902 21.8571 10.7143 21.2812 10.7143 20.5714H13.2857C13.2857 21.2812 12.7098 21.8571 12 21.8571ZM6.42857 18.6429V9.53571C6.42857 8.04643 7.00714 6.64821 8.05982 5.59554C9.1125 4.54286 10.5107 3.96429 12 3.96429C13.4893 3.96429 14.8875 4.54286 15.9402 5.59554C16.9929 6.64821 17.5714 8.04643 17.5714 9.53571V18.6429H6.42857Z"
        fill="#B4B4B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2703_3923">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const LogoRect: React.FC = () => (
  <svg
    width="120"
    height="19"
    viewBox="0 0 120 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2703_3927)">
      <path
        d="M19.4671 18.5266H4.79624C2.16301 18.5266 0 16.3636 0 13.7304V4.79624C0 2.16301 2.16301 0 4.79624 0H19.4671C22.1003 0 24.2633 2.16301 24.2633 4.79624V13.6364C24.2633 16.3636 22.1003 18.5266 19.4671 18.5266Z"
        fill="url(#paint0_linear_2703_3927)"
      />
      <path
        d="M7.71153 5.26646C7.89962 5.26646 7.89962 5.36051 7.89962 5.45455V7.33543H11.8495C13.7303 7.33543 14.3887 7.89969 14.3887 9.49844V12.884C14.3887 13.0721 14.2946 13.0721 14.2006 13.0721H13.1661C12.978 13.0721 12.978 12.9781 12.978 12.884V9.87461C12.978 9.02822 12.7899 8.74609 11.8495 8.74609H7.99367V12.9781C7.99367 13.1662 7.89962 13.1661 7.80558 13.1661H6.7711C6.58301 13.1661 6.58301 13.0721 6.58301 12.9781V5.5486C6.58301 5.36051 6.67705 5.36051 6.7711 5.36051H7.71153V5.26646Z"
        fill="white"
      />
      <path
        d="M17.4922 5.26646C17.6802 5.26646 17.6802 5.36051 17.6802 5.45455V6.48903C17.6802 6.67712 17.5862 6.77117 17.4922 6.77117H16.4577C16.2696 6.77117 16.1755 6.67712 16.1755 6.48903V5.5486C16.1755 5.36051 16.2696 5.36051 16.4577 5.36051H17.4922V5.26646ZM17.4922 7.61756C17.6802 7.61756 17.6802 7.7116 17.6802 7.89969V12.9781C17.6802 13.1662 17.5862 13.1661 17.4922 13.1661H16.4577C16.2696 13.1661 16.2696 13.0721 16.2696 12.9781V7.80565C16.2696 7.61756 16.3636 7.52352 16.4577 7.52352H17.4922V7.61756Z"
        fill="white"
      />
      <path
        d="M61.4107 2.53918C64.8904 2.53918 65.8308 3.66771 65.8308 6.77116V9.2163C65.8308 10.3448 65.3606 10.627 64.4201 10.627H51.4421V11.1912C51.4421 12.79 52.0063 13.4483 54.2634 13.4483H64.8903C65.1725 13.4483 65.3606 13.6364 65.3606 13.9185V16.0815C65.3606 16.3636 65.1725 16.5517 64.8903 16.5517H53.8872C49.2791 16.5517 47.8684 15.1411 47.8684 11.3793V7.7116C47.8684 4.04389 49.3731 2.53918 53.8872 2.53918H61.4107ZM51.4421 8.3699H61.7869C62.069 8.3699 62.2571 8.27586 62.2571 7.89969V6.8652C62.2571 5.83072 61.8809 5.45455 60.7524 5.45455H54.3574C52.1004 5.45455 51.5361 6.11285 51.5361 7.7116V8.3699H51.4421Z"
        fill="#00959F"
      />
      <path
        d="M83.511 2.53918C83.7931 2.53918 83.9812 2.72727 83.9812 3.0094V5.17241C83.9812 5.54859 83.7931 5.64263 83.511 5.64263H75.2351C73.0721 5.64263 72.4138 6.30094 72.4138 8.46395V15.9875C72.4138 16.3636 72.2257 16.5517 71.8495 16.5517H69.3104C68.9342 16.5517 68.7461 16.3636 68.7461 15.9875V7.7116C68.7461 4.04389 70.2508 2.53918 74.7649 2.53918H83.511Z"
        fill="#00959F"
      />
      <path
        d="M100.627 2.53918C100.909 2.53918 101.097 2.72727 101.097 3.0094V5.17241C101.097 5.54859 100.909 5.64263 100.627 5.64263H92.3513C90.1883 5.64263 89.624 6.30094 89.624 8.46395V15.9875C89.624 16.3636 89.4359 16.5517 89.0597 16.5517H86.5206C86.1444 16.5517 85.9563 16.3636 85.9563 15.9875V7.7116C85.9563 4.04389 87.461 2.53918 91.9751 2.53918H100.627Z"
        fill="#00959F"
      />
      <path
        d="M113.981 2.53918C118.589 2.53918 120 4.04389 120 7.7116V14.4828C120 16.1755 119.624 16.5517 118.119 16.5517H104.295C101.285 16.5517 100.345 15.4232 100.345 12.9781V11.7555C100.345 9.59248 101.285 8.46395 104.295 8.46395H116.426V7.89969C116.426 6.30094 115.862 5.64263 113.605 5.64263H104.107C103.825 5.64263 103.637 5.54859 103.637 5.17241V3.0094C103.637 2.72727 103.825 2.53918 104.107 2.53918H113.981ZM116.426 12.884V10.627H105.329C104.295 10.627 103.919 11.0972 103.919 11.8495V12.4138C103.919 13.3542 104.295 13.7304 105.329 13.7304H115.674C116.144 13.7304 116.426 13.5423 116.426 12.884Z"
        fill="#00959F"
      />
      <path
        d="M45.3292 2.53918C45.7054 2.53918 45.7994 2.72727 45.7994 3.0094V5.17241C45.7994 5.54859 45.6113 5.64263 45.3292 5.64263H38.2759V15.8934C38.2759 16.2696 38.0878 16.3636 37.8057 16.3636H35.3606C34.9844 16.3636 34.8903 16.1755 34.8903 15.8934V5.73668H29.4358C29.0596 5.73668 28.9656 5.54859 28.9656 5.26646V3.10345C28.9656 2.72727 29.1537 2.63323 29.4358 2.63323H45.3292V2.53918Z"
        fill="#00959F"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2703_3927"
        x1="-7.04925"
        y1="-7.88906"
        x2="35.8989"
        y2="30.4982"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="2.23767e-07" stopColor="#5EE193" />
        <stop offset="0.7104" stopColor="#4B9CB1" />
        <stop offset="1" stopColor="#436986" />
      </linearGradient>
      <clipPath id="clip0_2703_3927">
        <rect width="120" height="18.5266" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InfoIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.9043 16.5207 13 16.2833 13 16C13 15.7167 12.9043 15.479 12.713 15.287C12.521 15.0957 12.2833 15 12 15C11.7167 15 11.4793 15.0957 11.288 15.287C11.096 15.479 11 15.7167 11 16C11 16.2833 11.096 16.5207 11.288 16.712C11.4793 16.904 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
      fill="#989898"
    />
  </svg>
);

export const InterestIcon: React.FC = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2594_10423)">
      <path
        d="M8.516 12.6999C6.748 12.6999 5.316 14.1319 5.316 15.8999C5.316 17.6679 6.748 19.0999 8.516 19.0999C10.284 19.0999 11.716 17.6679 11.716 15.8999C11.716 14.1319 10.284 12.6999 8.516 12.6999ZM8.516 17.4999C7.636 17.4999 6.916 16.7799 6.916 15.8999C6.916 15.0199 7.636 14.2999 8.516 14.2999C9.396 14.2999 10.116 15.0199 10.116 15.8999C10.116 16.7799 9.396 17.4999 8.516 17.4999ZM13.3 12.6999V19.0999H19.7V12.6999H13.3ZM18.1 17.4999H14.9V14.2999H18.1V17.4999ZM8.5 3.8999L4.5 11.0999H12.5L8.5 3.8999ZM8.5 7.1959L9.78 9.4999H7.22L8.5 7.1959ZM18.3 4.2999C17.452 4.2999 16.852 4.7479 16.5 5.2359C16.148 4.7479 15.548 4.2999 14.7 4.2999C13.452 4.2999 12.5 5.3239 12.5 6.4999C12.5 8.0999 14.436 9.2359 16.5 11.0999C18.564 9.2359 20.5 8.0999 20.5 6.4999C20.5 5.3239 19.548 4.2999 18.3 4.2999ZM16.5 8.9799C15.34 8.0039 14.1 7.0599 14.1 6.4999C14.1 6.1559 14.38 5.8999 14.7 5.8999C14.948 5.8999 15.116 6.0359 15.284 6.1959L16.5 7.3399L17.716 6.1959C17.884 6.0359 18.052 5.8999 18.3 5.8999C18.62 5.8999 18.9 6.1559 18.9 6.4999C18.9 7.0599 17.66 8.0039 16.5 8.9799Z"
        fill="#0AC787"
      />
    </g>
    <defs>
      <clipPath id="clip0_2594_10423">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const CrownIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2594_10423)">
      <path
        d="M21.2228 6.05944L16.7202 8.83367L12.4026 3.07466C12.3853 3.05148 12.3627 3.03266 12.3368 3.01971C12.3109 3.00675 12.2824 3 12.2534 3C12.2244 3 12.1959 3.00675 12.17 3.01971C12.1441 3.03266 12.1215 3.05148 12.1042 3.07466L7.78895 8.83367L3.28401 6.05944C3.15213 5.97846 2.98091 6.08721 3.00173 6.24223L4.76946 19.6761C4.79492 19.8589 4.95225 20 5.13967 20H19.3718C19.5569 20 19.7165 19.8612 19.7397 19.6761L21.5074 6.24223C21.5259 6.08721 21.357 5.97846 21.2228 6.05944ZM18.3074 18.4174H6.19938L4.95457 8.94473L8.18924 10.9369L12.2546 5.51339L16.3199 10.9369L19.5546 8.94473L18.3074 18.4174ZM12.2546 11.439C10.8177 11.439 9.64924 12.6075 9.64924 14.0443C9.64924 15.4812 10.8177 16.6496 12.2546 16.6496C13.6914 16.6496 14.8599 15.4812 14.8599 14.0443C14.8599 12.6075 13.6914 11.439 12.2546 11.439ZM12.2546 15.1619C11.6391 15.1619 11.1393 14.6621 11.1393 14.0443C11.1393 13.4288 11.6391 12.9268 12.2546 12.9268C12.87 12.9268 13.3698 13.4265 13.3698 14.0443C13.3698 14.6598 12.87 15.1619 12.2546 15.1619"
        fill="#0AC787"
      />
    </g>
  </svg>
);

export const BlackCrownIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2594_10423)">
      <path
        d="M21.2228 6.05944L16.7202 8.83367L12.4026 3.07466C12.3853 3.05148 12.3627 3.03266 12.3368 3.01971C12.3109 3.00675 12.2824 3 12.2534 3C12.2244 3 12.1959 3.00675 12.17 3.01971C12.1441 3.03266 12.1215 3.05148 12.1042 3.07466L7.78895 8.83367L3.28401 6.05944C3.15213 5.97846 2.98091 6.08721 3.00173 6.24223L4.76946 19.6761C4.79492 19.8589 4.95225 20 5.13967 20H19.3718C19.5569 20 19.7165 19.8612 19.7397 19.6761L21.5074 6.24223C21.5259 6.08721 21.357 5.97846 21.2228 6.05944ZM18.3074 18.4174H6.19938L4.95457 8.94473L8.18924 10.9369L12.2546 5.51339L16.3199 10.9369L19.5546 8.94473L18.3074 18.4174ZM12.2546 11.439C10.8177 11.439 9.64924 12.6075 9.64924 14.0443C9.64924 15.4812 10.8177 16.6496 12.2546 16.6496C13.6914 16.6496 14.8599 15.4812 14.8599 14.0443C14.8599 12.6075 13.6914 11.439 12.2546 11.439ZM12.2546 15.1619C11.6391 15.1619 11.1393 14.6621 11.1393 14.0443C11.1393 13.4288 11.6391 12.9268 12.2546 12.9268C12.87 12.9268 13.3698 13.4265 13.3698 14.0443C13.3698 14.6598 12.87 15.1619 12.2546 15.1619"
        fill="black"
      />
    </g>
  </svg>
);

export const ArrowRightIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12H19"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5L19 12L12 19"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReportIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2171_3013)">
      <path
        d="M13.0498 20.96H19.9798V22.94H13.0498V20.96ZM13.0498 17H22.9498V18.98H13.0498V17ZM13.0498 13.04H22.9498V15.02H13.0498V13.04ZM24.9298 9.07998H20.7916C20.3758 7.93158 19.2868 7.09998 17.9998 7.09998C16.7128 7.09998 15.6238 7.93158 15.208 9.07998H11.0698C10.9312 9.07998 10.8025 9.08988 10.6738 9.11958C10.2877 9.19878 9.94124 9.39678 9.67394 9.66408C9.49574 9.84228 9.34724 10.0601 9.24824 10.2977C9.14924 10.5254 9.08984 10.7828 9.08984 11.06V24.92C9.08984 25.1873 9.14924 25.4546 9.24824 25.6922C9.34724 25.9298 9.49574 26.1377 9.67394 26.3258C9.94124 26.5931 10.2877 26.7911 10.6738 26.8703C10.8025 26.8901 10.9312 26.9 11.0698 26.9H24.9298C26.0188 26.9 26.9098 26.009 26.9098 24.92V11.06C26.9098 9.97098 26.0188 9.07998 24.9298 9.07998ZM17.9998 8.83248C18.4057 8.83248 18.7423 9.16908 18.7423 9.57498C18.7423 9.98088 18.4057 10.3175 17.9998 10.3175C17.5939 10.3175 17.2573 9.98088 17.2573 9.57498C17.2573 9.16908 17.5939 8.83248 17.9998 8.83248ZM24.9298 24.92H11.0698V11.06H24.9298V24.92Z"
        fill="#0AC787"
      />
    </g>
    <defs>
      <clipPath id="clip0_2171_3013">
        <rect width="24" height="24" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const RightArrowIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2171_3052)">
      <path
        d="M14.5898 22.59L19.1698 18L14.5898 13.41L15.9998 12L21.9998 18L15.9998 24L14.5898 22.59Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2171_3052">
        <rect width="24" height="24" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const EditIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4ZM14.325 9.675L13.625 8.975L15.025 10.375L14.325 9.675Z"
      fill="#0AC787"
    />
  </svg>
);

export const MarketIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18018 10.0402H8.17918C9.37798 10.0402 10.4103 10.906 10.5768 12.1048L12.1086 21.7618H21.8655C25.4952 21.7618 28.4256 18.8314 28.4256 15.2017V13.0372L10.71 13.0705"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.6726 13.0704L18.3023 8.97453C19.268 7.87563 20.9663 7.80903 21.9986 8.87463L26.1611 13.0371"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12.1085 21.7616L11.4092 25.9574C11.1428 27.6224 13.2074 28.6547 14.3729 27.4226L19.7009 21.7616"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.6295 21.1624C26.3611 21.3622 27.7264 22.8274 27.7264 24.6256C27.7264 26.557 26.1613 28.1221 24.2299 28.1221C22.2985 28.1221 20.7334 26.557 20.7334 24.6256C20.7334 23.4268 21.3328 22.3945 22.2319 21.7618"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const FieldIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6345 6.07805C16.3763 6.07805 17.0281 6.61751 17.163 7.35927L18.8488 17.0021C18.9162 17.3842 18.7364 17.6764 18.6465 17.7888C18.5341 17.9237 18.2868 18.126 17.9047 18.126H6.26138C5.85678 18.126 5.63201 17.9012 5.51962 17.7888C5.40723 17.654 5.22742 17.3842 5.31733 17.0021L7.00314 7.35927C7.138 6.61751 7.76737 6.07805 8.53161 6.07805H15.6345ZM15.6345 4.7294H8.48665C7.09304 4.7294 5.87926 5.74089 5.63201 7.11202L3.9462 16.7549C3.69894 18.1709 4.80034 19.4746 6.2389 19.4746H17.8822C19.3208 19.4746 20.4222 18.1709 20.1749 16.7549L18.4891 7.11202C18.2419 5.74089 17.0281 4.7294 15.6345 4.7294Z"
      fill="#E2FFF5"
    />
    <path
      d="M11.9929 4.95425V19.4747"
      stroke="#E2FFF5"
      strokeWidth="1.34865"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.99146 9.53967H18.1293"
      stroke="#E2FFF5"
      strokeWidth="1.26593"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.22754 13.7429H18.9613"
      stroke="#E2FFF5"
      strokeWidth="1.26593"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
