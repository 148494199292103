import React from "react";
import {
  MapIcon,
  TaskIcon,
  ExploreIcon,
  MoreIcon,
} from "@components/common/icon";

export const PREFIXED_URI = "/app";

export const authSelection = {
  login: "login",
  signup: "signup",
};

export const providers = {
  google: "google",
  facebook: "facebook",
};

export const providersQuery = {
  google: "id_token",
  facebook: "access_token",
};

export const paths = {
  home: `/home`,
  access: `/access`,
  otp: `/otp`,
  fgpass: `/fgpass`,
  leaderboard: `/leaderboard`,
  tasks: `/tasks`,
  explore: `/explore`,
  insight: `/explore/insight`,
  article: `/explore/insight/article/1`, //hardcoded
  marketplace: `/explore/marketplace`,
  product: `/explore/marketplace/product/1`, //hardcoded
  forms: `/forms`,
  more: `/more`,
  habitats: `/habitats`,
  test: `/test`,
};

export const URLS = {
  home: `${PREFIXED_URI}${paths.home}`,
  access: `${PREFIXED_URI}${paths.access}`,
  login: `${PREFIXED_URI}${paths.access}?mode=${authSelection.login}`,
  signup: `${PREFIXED_URI}${paths.access}?mode=${authSelection.signup}`,
  otp: `${PREFIXED_URI}${paths.otp}`,
  fgpass: `${PREFIXED_URI}${paths.fgpass}`,
  tasks: `${PREFIXED_URI}${paths.tasks}`,
  explore: `${PREFIXED_URI}${paths.explore}`,
  forms: `${PREFIXED_URI}${paths.forms}`,
  more: `${PREFIXED_URI}${paths.more}`,
  habitats: `${PREFIXED_URI}${paths.habitats}`,
  test: `${PREFIXED_URI}${paths.test}`,
};

export const tabs = [
  {
    key: `${URLS.home}/`,
    title: "Map",
    icon: <MapIcon />,
  },
  {
    key: `${URLS.tasks}/`,
    title: "Task",
    icon: <TaskIcon />,
    disabled: true,
  },
  {
    key: `${URLS.explore}/`,
    title: "Explore",
    icon: <ExploreIcon />,
    disabled: true,
  },
  {
    key: `${URLS.more}/`,
    title: "More",
    icon: <MoreIcon />,
  },
];

export const messages = {
  errors: {
    login: "ID or password mismatch",
    network: "Network Error",
  },
  others: {
    adding: "Adding..",
  },
};
