import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fetch from "isomorphic-fetch";
import { grapqhURL } from "@helpers/configs";
import { getUser, logout } from "@services/auth";
import { onError } from "@apollo/client/link/error";
import { navigate } from "gatsby";
import { URLS } from "@helpers/const";

const authLink = setContext((_, { headers }) => {
  // Get fresh token on each request
  const token = getUser().token;

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      console.log(`[GraphQL error]: Message: ${message}`, extensions);

      // Check for specific Strapi error codes
      if (
        message.includes("Forbidden") ||
        message.includes("Unauthorized") ||
        message.includes("Invalid token") ||
        message.includes("jwt") || // Add this to catch JWT-related errors
        extensions?.code === "UNAUTHENTICATED" ||
        extensions?.code === "FORBIDDEN"
      ) {
        console.log("Authentication error detected, logging out...");
        logout(() => {
          if (typeof window !== "undefined") {
            navigate(URLS.login);
          }
        });
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]:`, networkError);
    // Check for 401/403 network errors
    if (
      "statusCode" in networkError &&
      (networkError.statusCode === 401 || networkError.statusCode === 403)
    ) {
      logout(() => {
        if (typeof window !== "undefined") {
          navigate(URLS.login);
        }
      });
    }
  }
});

export const apollo = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${grapqhURL}`, fetch }),
  ]),
  cache: new InMemoryCache(),
});
