import Cookies from "js-cookie";
// import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import packageJson from "../../package.json";
import { apollo } from "@helpers/states-providers/graphql/client";
import { mutation } from "@helpers/queryStrings";
import { queries } from "@helpers/queryStrings";

export const isBrowser: boolean = typeof window !== "undefined";

const cookieToken = `${packageJson.name}_token`;
const cookieUser = `${packageJson.name}_user`;

export const getToken = (): any =>
  isBrowser && Cookies.get(cookieToken)
    ? JSON.parse(Cookies.get(cookieToken) || "{}")
    : {};

export const getUser = (): any =>
  isBrowser && Cookies.get(cookieUser)
    ? JSON.parse(Cookies.get(cookieUser) || "{}")
    : {};

const setToken = (token: string): void =>
  Cookies.set(cookieToken, JSON.stringify(token));

const setUser = (user: any): void =>
  Cookies.set(cookieUser, JSON.stringify(user));

export const handleAuth = (res: any): boolean => {
  const {
    jwt,
    user: { id, username },
  } = res.login || res.register || res.ssoLogin;

  if (jwt) {
    setUser({
      username,
      id,
      token: jwt,
    });
    setToken(jwt);
    return true;
  }

  return false;
};

export const isLoggedIn = (): boolean => {
  const user = getUser();
  return !!user?.token;
};

export const logout = (callback: () => void): void => {
  apollo.cache.reset();
  setUser({});
  setToken("");
  callback();
};

export const login = async (
  identifier: string,
  password: string
): Promise<boolean> => {
  try {
    const { data } = await apollo.mutate({
      mutation: mutation.login,
      variables: { identifier, password },
    });
    return handleAuth(data);
  } catch (error) {
    console.error("Login error:", error);
    return false;
  }
};

export const signup = async (
  email: string,
  username: string,
  password: string
): Promise<boolean> => {
  try {
    const { data } = await apollo.mutate({
      mutation: mutation.register,
      variables: { input: { username, email, password } },
    });
    return handleAuth(data);
  } catch (error) {
    console.error("Signup error:", error);
    return false;
  }
};

export const requestPasswordReset = async (email: string): Promise<boolean> => {
  // implementation
  return false; // placeholder return
};

export const verifyOTP = async (
  phoneNumber: string,
  code: string
): Promise<boolean> => {
  // implementation
  return false; // placeholder return
};

export const resetPassword = async (
  token: string,
  newPassword: string
): Promise<boolean> => {
  // implementation
  return false; // placeholder return
};

export const resendOTP = async (phoneNumber: string): Promise<boolean> => {
  // implementation
  return false; // placeholder return
};

export const validateToken = async (): Promise<boolean> => {
  try {
    const { data } = await apollo.query({
      query: queries.validateToken,
    });
    return !!data?.me?.id;
  } catch (error) {
    console.error("Token validation error:", error);
    return false;
  }
};
