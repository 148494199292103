import { gql } from "@apollo/client";

export const queries = {
  me: gql`
    query me {
      me {
        id
        username
        email
        role {
          id
          type
          name
        }
      }
    }
  `,
  habitatTypes: gql`
    query habitatTypes {
      habitatTypes {
        documentId
        name
      }
    }
  `,
  customHabitats: gql`
    query customHabitats {
      customHabitats {
        habitant_name
        documentId
        lang
        long
        remark
        fields {
          name
          documentId
        }
      }
    }
  `,
  landSizeUnits: gql`
    query landSizeUnits {
      landSizeUnits {
        documentId
        name
        unit
      }
    }
  `,
  cropVarieties: gql`
    query cropVarieties {
      cropVarieties {
        documentId
        name
      }
    }
  `,
  cropTypes: gql`
    query cropTypes {
      cropTypes {
        documentId
        name
      }
    }
  `,
  sampleLocalResolverQuery: gql`
    query sampleLocalResolverQuery {
      sampleLocalResolverQuery @client {
        data
      }
    }
  `,
  validateToken: gql`
    query validateToken {
      me {
        id
      }
    }
  `,
};

export const mutation = {
  login: gql`
    mutation login($identifier: String!, $password: String!) {
      login(input: { identifier: $identifier, password: $password }) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
  register: gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
      register(input: $input) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
  customCreateHabitat: gql`
    mutation customCreateHabitat($data: HabitatInput!) {
      customCreateHabitat(data: $data) {
        documentId
        habitant_name
        lang
        long
        remark
        fields {
          name
          documentId
        }
      }
    }
  `,
  customCreateField: gql`
    mutation customCreateField($data: FieldInput!) {
      customCreateField(data: $data) {
        documentId
      }
    }
  `,
  customCreateCrop: gql`
    mutation customCreateCrop($data: CropInput!, $cycleData: CycleDataInput!) {
      customCreateCrop(data: $data, cycleData: $cycleData) {
        documentId
      }
    }
  `,
};
